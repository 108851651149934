<button
  class="searchbox-button"
  [attr.aria-label]="'search'"
  [class.searchbox-open]="isOpen"
  (click)="clear(searchInput); open()">
  <i bossIcon [type]="ICONS.SEARCH" aria-hidden="true"></i>
</button>

<div class="searchbox" [class.searchbox-open]="isOpen">
  <label>
    <input
      #searchInput
      [placeholder]="'bossSearchBox.placeholder' | cxTranslate"
      aria-label="search"
      (focus)="open()"
      (click)="open()"
      (input)="search(searchInput.value)"
      (blur)="close($event, true, false)"
      (keydown.escape)="close($event)"
      (keydown.enter)="
        updateChosenWord(searchInput.value);
        saveRecentSearch(searchInput.value);
        launchSearchResult($event, searchInput.value)
      "
      (keydown.arrowup)="focusPreviousChild($event)"
      (keydown.arrowdown)="focusNextChild($event)"
      value="{{ chosenWord }}" />
  </label>

  <button
    class="search-btn"
    [attr.aria-label]="'search'"
    [class.searchbox-open]="isOpen"
    (click)="
      updateChosenWord(searchInput.value);
      saveRecentSearch(searchInput.value);
      launchSearchResult($event, searchInput.value)
    ">
    <cx-icon [type]="iconTypes.SEARCH" aria-hidden="true"></cx-icon>
  </button>
</div>

<div class="results" [class.searchbox-open]="isOpen">
  <ng-container *ngIf="!enoughCharsInQuery">
    <!-- Recent searches -->
    <div *ngIf="recentSearches?.length" class="results-column">
      <span class="results-column-title copy-bold text-transform-none">{{
        'bossSearchBox.recentSearches' | cxTranslate
      }}</span>
      <a
        *ngFor="let recentSearch of recentSearches"
        class="boss-searchbox-link copy"
        [routerLink]="
          {
            cxRoute: 'search',
            params: { query: recentSearch }
          } | cxUrl
        "
        (keydown.arrowup)="focusPreviousChild($event)"
        (keydown.arrowdown)="focusNextChild($event)"
        (keydown.enter)="close($event, true)"
        (keydown.escape)="close($event, true)"
        (blur)="close($event)"
        (mousedown)="preventDefault($event)"
        (click)="updateChosenWord(recentSearch); close($event, true)">
        <span class="copy" [innerHTML]="recentSearch | bossSearchHighlight: searchInput.value"></span>
      </a>
    </div>

    <!-- Popular searches -->
    <div *ngIf="popularSearches?.length" class="results-column">
      <span class="results-column-title copy-bold text-transform-none">{{
        'bossSearchBox.popularSearchTerms' | cxTranslate
      }}</span>
      <a
        *ngFor="let popularSearch of popularSearches"
        class="boss-searchbox-link copy text-capitalize"
        [routerLink]="
          {
            cxRoute: 'search',
            params: { query: popularSearch }
          } | cxUrl
        "
        (keydown.arrowup)="focusPreviousChild($event)"
        (keydown.arrowdown)="focusNextChild($event)"
        (keydown.enter)="close($event, true)"
        (keydown.escape)="close($event, true)"
        (blur)="close($event)"
        (mousedown)="preventDefault($event)"
        (click)="updateChosenWord(popularSearch); saveRecentSearch(popularSearch); close($event, true)">
        {{ popularSearch }}
      </a>
    </div>
  </ng-container>

  <ng-container *ngIf="bossResults$ | async as results">
    <ng-container *ngIf="enoughCharsInQuery">
      <ng-container *ngIf="noSearchResults; else noResults">
        <!-- Products -->
        <div class="products results-column" *ngIf="results.products?.length">
          <span class="results-column-title copy-bold text-transform-none">{{
            'bossSearchBox.productsHeader' | cxTranslate
          }}</span>
          <a
            *ngFor="let product of results.products"
            class="boss-searchbox-link copy"
            [routerLink]="product.url"
            [class.has-media]="config.displayProductImages"
            (keydown.arrowup)="focusPreviousChild($event)"
            (keydown.arrowdown)="focusNextChild($event)"
            (keydown.enter)="close($event, true)"
            (keydown.escape)="close($event, true)"
            (blur)="close($event)"
            (mousedown)="preventDefault($event)"
            (click)="
              updateChosenWord(popularSearch);
              saveRecentSearch(searchInput.value);
              dispatchProductEvent({
                freeText: searchInput.value,
                productCode: product.code
              });
              close($event, true)
            ">
            <cx-media
              *ngIf="config.displayProductImages"
              [container]="product.images[0]"
              format="thumbnail"
              [alt]="product.summary"></cx-media>
            <p class="copy" [innerHTML]="product.title | bossSearchHighlight: searchInput.value"></p>
          </a>
          <a
            class="boss-searchbox-link show-all-btn"
            [routerLink]="
              {
                cxRoute: 'search',
                params: { query: searchInput.value }
              } | cxUrl
            "
            (keydown.arrowup)="focusPreviousChild($event)"
            (keydown.arrowdown)="focusNextChild($event)"
            (keydown.enter)="close($event, true)"
            (keydown.escape)="close($event, true)"
            (blur)="close($event)"
            (mousedown)="preventDefault($event)"
            (click)="updateChosenWord(searchInput.value); saveRecentSearch(searchInput.value); close($event, true)">
            <span class="copy-bold">Alle anzeigen</span>
          </a>
        </div>

        <!-- Categories -->
        <div class="results-column">
          <span class="results-column-title copy-bold text-transform-none">{{
            'bossSearchBox.categoriesHeader' | cxTranslate
          }}</span>
          <ng-container *ngIf="searchCategories?.length; else noMatches">
            <a
              *ngFor="let searchCategory of searchCategories"
              class="boss-searchbox-link copy"
              [routerLink]="searchCategory.url"
              (keydown.arrowup)="focusPreviousChild($event)"
              (keydown.arrowdown)="focusNextChild($event)"
              (keydown.enter)="close($event, true)"
              (keydown.escape)="close($event, true)"
              (blur)="close($event)"
              (mousedown)="preventDefault($event)"
              (click)="updateChosenWord(searchCategory.name); saveRecentSearch(searchInput.value); close($event, true)">
              <span class="copy" [innerHTML]="searchCategory.name | bossSearchHighlight: searchInput.value"></span>
            </a>
          </ng-container>
        </div>

        <!-- Suggestions -->
        <div class="suggestions results-column">
          <span class="results-column-title copy-bold text-transform-none">{{
            'bossSearchBox.suggestionsHeader' | cxTranslate
          }}</span>
          <ng-container *ngIf="results.suggestions?.length; else noMatches">
            <a
              *ngFor="let suggestion of results.suggestions"
              class="boss-searchbox-link copy"
              [innerHTML]="suggestion | bossSearchHighlight: searchInput.value"
              [routerLink]="
                {
                  cxRoute: 'search',
                  params: { query: suggestion }
                } | cxUrl
              "
              (keydown.arrowup)="focusPreviousChild($event)"
              (keydown.arrowdown)="focusNextChild($event)"
              (keydown.enter)="close($event, true)"
              (keydown.escape)="close($event, true)"
              (blur)="close($event)"
              (mousedown)="preventDefault($event)"
              (click)="
                updateChosenWord(suggestion);
                saveRecentSearch(suggestion);
                dispatchSuggestionEvent({
                  freeText: searchInput.value,
                  selectedSuggestion: suggestion,
                  searchSuggestions: results.suggestions
                })
              ">
            </a>
          </ng-container>
        </div>

        <div class="results-column">
          <div class="stores">
            <span class="results-column-title copy-bold text-transform-none">{{
              'bossSearchBox.storeHeader' | cxTranslate
            }}</span>
            <ng-container *ngIf="searchMarkets?.length; else noMatches">
              <a
                *ngFor="let store of searchMarkets"
                class="boss-searchbox-link copy"
                [routerLink]="store.url"
                (keydown.arrowup)="focusPreviousChild($event)"
                (keydown.arrowdown)="focusNextChild($event)"
                (keydown.enter)="close($event, true)"
                (keydown.escape)="close($event, true)"
                (blur)="close($event)"
                (mousedown)="preventDefault($event)"
                (click)="updateChosenWord(store.name); close($event, true)">
                <span class="copy" [innerHTML]="store.displayName | bossSearchHighlight: searchInput.value"></span>
              </a>
            </ng-container>
          </div>

          <div class="service">
            <span class="results-column-title copy-bold text-transform-none">{{
              'bossSearchBox.moreContent' | cxTranslate
            }}</span>
            <ng-container *ngIf="searchServiceAndCareer?.length; else noMatches">
              <a
                *ngFor="let service of searchServiceAndCareer"
                class="boss-searchbox-link copy"
                [routerLink]="service.url"
                (keydown.arrowup)="focusPreviousChild($event)"
                (keydown.arrowdown)="focusNextChild($event)"
                (keydown.enter)="close($event, true)"
                (keydown.escape)="close($event, true)"
                (blur)="close($event)"
                (mousedown)="preventDefault($event)"
                (click)="updateChosenWord(service.name); close($event, true)">
                <span class="copy" [innerHTML]="service.name"></span>
              </a>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #noMatches>
    <p class="copy">{{ 'bossSearchBox.noMatches' | cxTranslate }}</p>
  </ng-template>

  <ng-template #noResults>
    <div class="col-lg-6 col-12">
      <h4 class="boss-h4 text-transform-none pl-0">Entschuldigung</h4>
      <p class="copy"
        >wir konnten leider nichts zu deinem Begriff “<span class="magenta">{{ searchInput.value }}</span
        >” finden.
      </p>
    </div>
    <div class="col-lg-6 col-12">
      <h6 class="boss-h6 text-transform-none">Du brauchst telefonische Beratung? Wende dich gerne an uns</h6>
      <div class="d-flex align-items-start details">
        <i bossIcon class="pr-3" [type]="ICONS.PHONE" aria-hidden="true"></i>
        <div>
          <a class="underline" href="tel:05731609860" (mousedown)="preventDefault($event)" (click)="close($event, true)"
            >05731 - 609 860</a
          >
          <p class="copy">Montags bis Freitags: 8:00 - 20:00 Uhr <br />Samstags: 10:00 - 15:00 Uhr</p>
        </div>
      </div>
      <div class="d-flex align-items-start details">
        <i bossIcon class="pr-3" [type]="ICONS.MAIL_SEND" aria-hidden="true"></i>
        <div>
          <p class="copy"
            >oder nutze unser
            <a
              class="underline"
              [routerLink]="['/service/contact/form']"
              (mousedown)="preventDefault($event)"
              (click)="close($event)"
              >Kontaktformular</a
            ></p
          >
        </div>
      </div>
    </div>
  </ng-template>
</div>
