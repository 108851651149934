<a
  bossSetActiveState
  [elementUrl]="(isLoggedIn | async) ? 'my-account' : 'login'"
  class="profile"
  [routerLink]="myAccountRoute">
  <!-- User logged in-->
  <ng-container *ngIf="isLoggedIn | async; else notLoggedIn">
    <i bossIcon class="user-icon filled" [type]="bossIconConfig.USER" aria-hidden="true"></i>
    <span class="title">{{ 'bossUserAccount.myAccount' | cxTranslate }}</span>
  </ng-container>

  <!-- User NOT logged in-->
  <ng-template #notLoggedIn>
    <i bossIcon class="user-icon" [type]="bossIconConfig.USER" aria-hidden="true"></i>
    <span class="title">{{ 'bossUserAccount.login' | cxTranslate }}</span>
  </ng-template>
</a>
