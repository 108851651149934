import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { carouselConfigBrowser, carouselConfigServer } from './boss-carousel-base.config';
import { WindowRef } from '@spartacus/core';

@Component({
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export abstract class BossCarouselBaseComponent implements OnInit {
  swiperConfig: SwiperOptions;

  items: any[]; // eslint-disable-line

  protected swiperConfigServer: SwiperOptions = carouselConfigServer;

  protected swiperConfigBrowser: SwiperOptions = carouselConfigBrowser;

  protected constructor(private winRef: WindowRef) {}

  ngOnInit(): void {
    this.loadSwiperConfig();
    this.loadItems();
  }

  // eslint-disable-next-line
  trackById(index: number, item: any): string {
    return item.uid;
  }

  protected loadSwiperConfig(): void {
    this.swiperConfig = this.winRef.isBrowser() ? this.swiperConfigBrowser : this.swiperConfigServer;
  }

  protected abstract loadItems(): void;
}
