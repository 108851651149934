<div class="store-availability">
  <div class="store-availability-header">
    <p class="store-availability-headline boss-h1">{{ 'productDetails.clickAndCollect' | cxTranslate }}</p>

    <p class="store-availability-subheading">{{ 'productDetails.storeAvailability.subheading' | cxTranslate }}</p>

    <div class="store-availability-search">
      <form [formGroup]="storeSearchForm" (submit)="searchStores()">
        <div class="store-availability-search-form">
          <input
            placeholder="{{ 'storeFinder.query.placeholder' | cxTranslate }}"
            class="boss-form-control"
            type="text"
            formControlName="queryControl" />
          <button class="boss-btn-icon" type="submit" [attr.aria-label]="'storeFinder.search' | cxTranslate">
            <cx-icon type="SEARCH"></cx-icon>
          </button>
        </div>
      </form>
      <div class="store-availability-toggle">
        <label class="boss-filter-toggle__switch">
          <input type="checkbox" [value]="showOnlyAvailable" (change)="toggle()" />
          <span class="boss-filter-toggle__slider"></span>
        </label>
        <p>{{ 'productDetails.storeAvailability.showOnlyAvailable' | cxTranslate }}</p>
      </div>
    </div>

    <hr class="boss-horizontal-line" />
  </div>

  <div class="store-availability-body">
    <ng-container *ngIf="!isLoading; else spinner">
      <ng-container *ngIf="pointOfServices.length; else noStoresFound">
        <form [formGroup]="storeAvailabilityForm" (submit)="submitAndClose()">
          <ul class="store-availability-list">
            <li *ngFor="let pointOfService of pointOfServices | slice: 0:storesToDisplay; trackBy: trackByName">
              <boss-store-availability-item
                [pointOfService]="pointOfService"
                [control]="storeAvailabilityForm.get('selectedStoreControl')"
                (store)="selectStore($event)"></boss-store-availability-item>
            </li>
          </ul>

          <ng-container *ngIf="pointOfServices.length > storesToDisplay">
            <div class="store-availability-button-wrapper">
              <button class="boss-btn boss-btn-as-link text-underline" type="button" (click)="loadMore()"
                >{{ 'productList.showMoreBtn' | cxTranslate }}
              </button>
            </div>
          </ng-container>

          <div class="store-availability-footer">
            <button
              class="boss-btn boss-btn-magenta d-block"
              type="submit"
              [attr.disabled]="!storeAvailabilityForm.valid ? true : null"
              >{{ 'addToCart.addToCartWishlist' | cxTranslate }}</button
            >
          </div>
        </form>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #noStoresFound>
  <p
    ><strong>{{ 'productDetails.storeAvailability.noStoresFound' | cxTranslate }}</strong></p
  >
</ng-template>

<ng-template #spinner>
  <div class="boss-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
