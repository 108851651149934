<nav *ngIf="nodes?.length" class="boss-navigation" role="navigation">
  <ul class="nodes">
    <ng-container *ngIf="isMobile; else desktop">
      <!-- Go back button -->
      <li *ngIf="prevNodes?.length > 1" class="node node-mobile">
        <button class="back" (click)="goBack()">
          <i bossIcon [type]="bossIconConfig.CARET_LEFT" aria-hidden="true"></i>
          <span class="node-title"
            >Zurück zu <span class="prev-title">{{ prevNodes[prevNodes.length - 2].title }}</span></span
          >
        </button>
      </li>
      <!-- Parent category -->
      <li *ngIf="prevNodes?.length > 1" class="node node-parent">
        <button class="back" (click)="goToParent(prevNodes[prevNodes.length - 1].url)">
          <span class="node-title">{{ prevNodes[prevNodes.length - 1].title }}</span>
        </button>
      </li>
      <!-- Mobile nodes -->
      <li *ngFor="let node of mobileNodes" class="node">
        <button (click)="clickMobileNode(node)">
          <span class="node-title">{{ node.title }}</span>
          <i *ngIf="node.hasArrow" bossIcon [type]="bossIconConfig.CARET_RIGHT" aria-hidden="true"></i>
        </button>
      </li>
    </ng-container>
    <ng-template #desktop>
      <li
        *ngFor="let node of nodes; let i = index"
        class="node"
        [class.active]="itemsVisibility[i]"
        [ngClass]="node.styleClass || ''">
        <button (click)="clickNode(i)">
          <span class="node-title">{{ node.title }}</span>
        </button>

        <div *ngIf="node.children?.length && itemsVisibility[i]" class="sublevel">
          <p class="show-whole-category">
            <cx-generic-link [url]="node.url">Alles in {{ node.title }}</cx-generic-link>
          </p>

          <div class="sublevel-list-wrapper">
            <ul class="sublevel-list" [class.with-image]="node.banner">
              <li *ngFor="let child of node.children; let j = index" class="sublevel-item">
                <p class="section-title" [class.title-as-link]="node.skipLinks">
                  <cx-generic-link [url]="child.url">
                    {{ child.title }}
                  </cx-generic-link>
                </p>

                <ng-container *ngIf="!node.skipLinks">
                  <p *ngFor="let link of child.links | slice: 0:linksPerColumn; trackBy: trackByFn">
                    <cx-generic-link [url]="link.url">
                      {{ link.linkName }}
                    </cx-generic-link>
                  </p>

                  <ng-container *ngIf="child.links.length > linksPerColumn">
                    <p class="show-all">
                      <cx-generic-link [url]="child.url">{{ 'bossNavHeader.showAll' | cxTranslate }}</cx-generic-link>
                    </p>
                  </ng-container>
                </ng-container>
              </li>
            </ul>

            <img
              *ngIf="node.banner as banner"
              bossMedia
              [container]="banner.media"
              [alt]="banner.media?.altText || node.title"
              class="category-image" />
          </div>
        </div>
      </li>
    </ng-template>
  </ul>
</nav>
