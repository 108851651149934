<ng-container *ngIf="product.energyEfficiencies?.length">
  <ng-container *ngIf="expandedDesign; else defaultDesign">
    <div class="energy-labels-expanded" *ngFor="let energyEfficiencyClass of product.energyEfficiencies; index as i">
      <boss-energy-label [energyEfficiency]="energyEfficiencyClass" [product]="product"></boss-energy-label>
      <span class="boss-energy-label-text">{{ energyEfficiencyClass.displayName }}</span>
    </div>
  </ng-container>

  <ng-template #defaultDesign>
    <ng-container *ngFor="let energyEfficiencyClass of product.energyEfficiencies; index as i">
      <boss-energy-label
        *ngIf="maxVisibleLabels === null || i < maxVisibleLabels"
        [energyEfficiency]="energyEfficiencyClass"
        [product]="product"></boss-energy-label>
    </ng-container>
  </ng-template>
</ng-container>
