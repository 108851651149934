<ng-container *ngIf="augmentedRealityId">
  <h2 class="ar_product">Produkt in 3D erleben</h2>
  <boss-consent [consents]="['Rooom', 'Synthesia']">
    <section>
      <div class="ar-product-container">
        <iframe
          [src]="augmentedRealityId | bossSafe: 'resourceUrl'"
          title="rooom-viewer"
          loading="lazy"
          allowvr="yes"
          allow="vr; xr; accelerometer; magnetometer; gyroscope; autoplay;"
          allowfullscreen=""
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
          class="arid_iframe"></iframe>
      </div>
    </section>
    <ng-container ngProjectAs="cookiesName"><b>Rooom</b> und <b>Synthesia</b></ng-container>
  </boss-consent>
</ng-container>
