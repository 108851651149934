<div class="product-carousel-template">
  <ng-content select="[headline]"></ng-content>

  <div class="product-carousel-template-navigation">
    <hr />
    <span class="product-carousel-template-amount">{{ quantity }} Produkte</span>
    <button
      [attr.aria-label]="'bossProductCarousel.labelButtonPrev' | cxTranslate"
      [ngClass]="navigation?.prev"
      class="boss-btn boss-btn-primary">
      <i bossIcon [type]="bossIconConfig.ARROW_LEFT" [iconSize]="16" aria-hidden="true"></i>
    </button>
    <button
      [attr.aria-label]="'bossProductCarousel.labelButtonNext' | cxTranslate"
      [ngClass]="navigation?.next"
      class="boss-btn boss-btn-primary">
      <i bossIcon [type]="bossIconConfig.ARROW_RIGHT" [iconSize]="16" aria-hidden="true"></i>
    </button>
  </div>

  <ng-content></ng-content>
</div>
