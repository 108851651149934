import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { asyncScheduler, Observable, of } from 'rxjs';
import { catchError, map, observeOn, shareReplay, tap } from 'rxjs/operators';
import { BOSS_NAVIGATION_BAR_DATA, BossTransferStateService } from '../../shared/services/boss-transfer-state';
import { NavigationBarController, NavigationSubLevel } from './boss-navigation.model';

@Injectable({
  providedIn: 'root',
})
export class BossNavigationService {
  constructor(
    private http: HttpClient,
    private occEndpoints: OccEndpointsService,
    private bossTransferState: BossTransferStateService,
  ) {}

  getNavigationSubLevels(): Observable<NavigationSubLevel[]> {
    const navigationDataFromState = this.bossTransferState.get<NavigationSubLevel[]>(BOSS_NAVIGATION_BAR_DATA);

    return (
      navigationDataFromState ||
      this.fetchNavigationData().pipe(
        observeOn(asyncScheduler),
        tap((data: NavigationSubLevel[]) => {
          this.bossTransferState.set(BOSS_NAVIGATION_BAR_DATA, data);
        }),
        catchError((error) => {
          console.error('Error fetching navigation data:', error);
          return of([]);
        }),
      )
    );
  }

  private fetchNavigationData(): Observable<NavigationSubLevel[]> {
    return this.http.get<NavigationBarController>(this.occEndpoints.buildUrl('navigationbar')).pipe(
      map((nav: NavigationBarController) => nav.navigationBarComponents),
      shareReplay(1),
    );
  }
}
