<div
  *ngIf="range"
  id="{{ facet.code }}"
  [formGroup]="form"
  [class.boss-filter-range-left]="isCurrency"
  class="boss-filter-range">
  <div class="boss-filter-range-input boss-form-element">
    <label class="boss-form-label">{{ 'common.from' | cxTranslate }}</label>
    <input
      [attr.aria-label]="'productDetails.facets.priceFrom' | cxTranslate: { filterName: facet?.name || '' }"
      formControlName="min"
      class="boss-form-control"
      tabindex="0"
      type="number" />
    <span class="boss-filter-range-placeholder">{{ getFilterTypeSymbol() }}</span>
    <cx-form-errors [control]="getFormItemControl('min')"></cx-form-errors>
  </div>

  <div class="boss-filter-range-input boss-form-element">
    <label class="boss-form-label">{{ 'common.to' | cxTranslate }}</label>
    <input
      [attr.aria-label]="'productDetails.facets.priceTo' | cxTranslate: { filterName: facet?.name || '' }"
      formControlName="max"
      class="boss-form-control"
      tabindex="0"
      type="number" />
    <span class="boss-filter-range-placeholder">{{ getFilterTypeSymbol() }}</span>
    <cx-form-errors [control]="getFormItemControl('max')"></cx-form-errors>
  </div>
</div>

<boss-range-slider [range]="range" [min]="min" [max]="max" (changes)="updateForm($event)"></boss-range-slider>

<div *ngIf="areButtonsVisible()" class="boss-filter-range-buttons">
  <button (click)="reset()" class="boss-link" type="button" tabindex="0">{{ 'common.reset' | cxTranslate }}</button>
  <a
    [queryParams]="getLinkParams()"
    [class.disabled]="isButtonDisabled()"
    [routerLink]=""
    class="boss-btn boss-btn-primary"
    type="submit"
    tabindex="0">
    {{ 'common.take' | cxTranslate }}
  </a>
</div>
