import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Product, ProductScope, ProductService, WindowRef } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { BossWeeklyOfferComponentCmsData } from '../../shared/models';
import { SwiperOptions } from 'swiper';
import { BossCarouselBaseComponent } from '../../shared/components/boss-carousel-base/boss-carousel-base.component';
import {
  carouselConfigBrowser,
  carouselConfigServer,
} from '../../shared/components/boss-carousel-base/boss-carousel-base.config';
import { getUniqueClass } from '../../shared/utils/utils';

@Component({
  selector: 'boss-weekly-offer',
  templateUrl: './weekly-offer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossWeeklyOfferComponent extends BossCarouselBaseComponent implements OnDestroy {
  items: Product[];

  prevClass: string = getUniqueClass('weekly-offer-button-prev');

  nextClass: string = getUniqueClass('weekly-offer-button-next');

  protected readonly swiperConfigServer: SwiperOptions = carouselConfigServer;

  protected readonly swiperConfigBrowser: SwiperOptions = {
    ...carouselConfigBrowser,
    navigation: {
      prevEl: `.${this.prevClass}`,
      nextEl: `.${this.nextClass}`,
    },
  };

  private onDestroy$ = new Subject<void>();

  constructor(
    private componentData: CmsComponentData<BossWeeklyOfferComponentCmsData>,
    private productService: ProductService,
    private cdRef: ChangeDetectorRef,
    winRef: WindowRef,
  ) {
    super(winRef);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  loadItems(): void {
    this.componentData.data$
      .pipe(
        map((data) => data.productList?.trim().split(' ') ?? []),
        map((codes: string[]) => codes.map((code: string) => this.productService.get(code, ProductScope.DETAILS))),
        switchMap((items$: Observable<Product>[]) => combineLatest([...items$])),
        map((items: Product[]) => items.filter((item: Product) => !!item)),
        map((items: Product[]) => items.filter((item: Product) => item.purchasable)),
        takeUntil(this.onDestroy$),
      )
      .subscribe((items: Product[]) => {
        this.items = items;

        this.cdRef.detectChanges();
      });
  }
}
