<ng-container *ngIf="messages$ | async as messages">
  <!-- Success message -->
  <div
    class="boss-alert boss-alert-success"
    *ngFor="let confirmationMessage of messages[messageType.MSG_TYPE_CONFIRMATION]; let i = index">
    <div class="boss-alert-wrapper">
      <span class="boss-alert-text">
        {{ confirmationMessage | cxTranslate }}
      </span>
      <button
        class="boss-alert-close"
        type="button"
        (click)="clear(messageType.MSG_TYPE_CONFIRMATION, i)"
        [attr.aria-label]="'common.close' | cxTranslate">
        <i bossIcon [type]="bossIconConfig.CLOSE" aria-hidden="true"></i>
      </button>
    </div>
  </div>

  <!-- Info message -->
  <div
    class="boss-alert boss-alert-info"
    *ngFor="let infoMessage of messages[messageType.MSG_TYPE_INFO]; let i = index">
    <div class="boss-alert-wrapper">
      <span class="boss-alert-text">
        {{ infoMessage | cxTranslate }}
      </span>
      <button
        class="boss-alert-close"
        type="button"
        (click)="clear(messageType.MSG_TYPE_INFO, i)"
        [attr.aria-label]="'common.close' | cxTranslate">
        <i bossIcon [type]="bossIconConfig.CLOSE" aria-hidden="true"></i>
      </button>
    </div>
  </div>

  <!-- Warning message -->
  <div
    class="boss-alert boss-alert-warning"
    *ngFor="let warningMessage of messages[messageType.MSG_TYPE_WARNING]; let i = index">
    <div class="boss-alert-wrapper">
      <span class="boss-alert-text">
        {{ warningMessage | cxTranslate }}
      </span>
      <button
        class="boss-alert-close"
        type="button"
        (click)="clear(messageType.MSG_TYPE_WARNING, i)"
        [attr.aria-label]="'common.close' | cxTranslate">
        <i bossIcon [type]="bossIconConfig.CLOSE" aria-hidden="true"></i>
      </button>
    </div>
  </div>

  <!-- Error message -->
  <div
    class="boss-alert boss-alert-danger"
    *ngFor="let errorMessage of messages[messageType.MSG_TYPE_ERROR]; let i = index">
    <div class="boss-alert-wrapper">
      <span class="boss-alert-text">
        {{ errorMessage | cxTranslate }}
      </span>
      <button
        class="boss-alert-close"
        type="button"
        (click)="clear(messageType.MSG_TYPE_ERROR, i)"
        [attr.aria-label]="'common.close' | cxTranslate">
        <i bossIcon [type]="bossIconConfig.CLOSE" aria-hidden="true"></i>
      </button>
    </div>
  </div>
</ng-container>
