<ng-container *ngIf="pointOfService">
  <div class="store-availability-item">
    <div class="store-availability-item-checkbox">
      <label>
        <input
          type="radio"
          class="boss-form-control-radio"
          [name]="pointOfService.name"
          [formControl]="$any(control)"
          [id]="pointOfService.name"
          [value]="pointOfService.name"
          (change)="selectStore(pointOfService.name)"
          [attr.disabled]="pointOfService.stockInfo?.stockLevel ? null : true" />
        <span class="boss-form-check-label"></span>
      </label>
    </div>
    <div class="store-availability-item-info">
      <p [class.silk-gray-text]="!pointOfService.stockInfo?.stockLevel" class="store-availability-item-name"
        ><strong>Möbel Boss Markt, {{ pointOfService.displayName }}</strong></p
      >

      <p
        >{{ pointOfService.address?.line1 }}, {{ pointOfService.address?.town }}
        {{ pointOfService.address?.postalCode }}</p
      >

      <p class="store-availability-item-opening-hours">{{ pointOfService.formattedHours }}</p>

      <div class="store-availability-item-status">
        <boss-status-label [status]="pointOfService.stockInfoStatus">
          {{ getStockInfoTranslation(pointOfService.stockInfo.stockLevelStatus) | async | titlecase }}
        </boss-status-label>

        <boss-status-label [status]="pointOfService.isInDisplayStatus">
          {{
            pointOfService.isInDisplay
              ? ('productDetails.storeAvailability.displayed' | cxTranslate)
              : ('productDetails.storeAvailability.notDisplayed' | cxTranslate)
          }}
        </boss-status-label>
      </div>
    </div>
  </div>
</ng-container>
