<div class="boss-product-card" *ngIf="product">
  <div class="boss-product-card-header">
    <boss-product-status [product]="product" [max]="2"></boss-product-status>
    <button
      [attr.aria-label]="
        (isOnWishlist ? 'bossProductCard.wishlist.labelRemove' : 'bossProductCard.wishlist.labelAdd') | cxTranslate
      "
      class="wishlist-btn"
      [class.active]="isOnWishlist"
      (click)="toggleFromWishlist()">
      <i
        bossIcon
        class="wishlist-icon"
        [type]="isOnWishlist ? bossIconConfig.HEART_3_FILL : bossIconConfig.HEART_3"
        aria-hidden="true"></i>
    </button>
  </div>

  <boss-energy-labels [product]="product" [maxVisibleLabels]="4"></boss-energy-labels>

  <a
    [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
    [class.has-hover]="product.hoverImage?.HOVER"
    class="boss-product-card-link">
    <img
      [alt]="'bossProductCard.image.labelPrimary' | cxTranslate"
      [container]="product.images?.PRIMARY"
      [format]="'productListing'"
      bossMedia
      class="boss-product-card-image" />
    <img
      *ngIf="product.hoverImage?.HOVER"
      [alt]="'bossProductCard.image.labelSecondary' | cxTranslate"
      [container]="product.hoverImage?.HOVER"
      [format]="'productListing'"
      bossMedia
      class="boss-product-card-image" />
    <span class="boss-product-card-name">{{ product.title }}</span>
  </a>

  <boss-swatches
    class="boss-swatches"
    [product]="product"
    (changeProduct)="updateProduct($event)"
    [redirect]="true"></boss-swatches>

  <boss-price [product]="product"></boss-price>
</div>
