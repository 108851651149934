import {
  Component,
  ElementRef,
  Input,
  AfterViewInit,
  ViewChild,
  ViewEncapsulation,
  ChangeDetectionStrategy,
} from '@angular/core';
import { bossIconConfig } from '../../utils/boss-icon-config';
import { BossSimpleCollapseState } from './boss-simple-collapse.model';
import { WindowRef } from '@spartacus/core';

@Component({
  selector: 'boss-simple-collapse',
  templateUrl: './boss-simple-collapse.component.html',
  styleUrls: ['./boss-simple-collapse.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossSimpleCollapseComponent implements AfterViewInit {
  @Input() header: string = '';

  @Input() collapsedIcon: string = bossIconConfig.ADD;

  @Input() expandedIcon: string = bossIconConfig.SUBTRACT;

  @Input() initalState = BossSimpleCollapseState.closed;

  @Input() headerClasses = [];

  @Input() contentClasses = [];

  @Input() useDefaultHeight = false;

  @ViewChild('collapseRef') collapseRef: ElementRef<HTMLDivElement>;

  collapsed: boolean = true;

  collapseStyles: { [key: string]: unknown } = { height: 0 };

  constructor(private winRef: WindowRef) {}

  ngAfterViewInit(): void {
    if (this.initalState === BossSimpleCollapseState.open) {
      this.collapsed = false;
      this.calculateCollapseState();
    }
  }

  toggleCollapse(): void {
    this.collapsed = !this.collapsed;
    this.calculateCollapseState();
  }

  private calculateCollapseState(): void {
    if (this.winRef.isBrowser()) {
      const padding: number = parseInt(
        this.winRef.nativeWindow.getComputedStyle(this.collapseRef?.nativeElement, null).paddingBottom,
        10,
      );

      if (this.collapseRef?.nativeElement?.scrollHeight && !this.useDefaultHeight) {
        this.collapseStyles.height = this.collapsed ? 0 : `${this.collapseRef?.nativeElement.scrollHeight + padding}px`;
      } else {
        this.collapseStyles.height = this.collapsed ? 0 : `initial`;
      }
    }
  }
}
