import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  BreadcrumbMeta,
  PageBreadcrumbResolver,
  PageDescriptionResolver,
  PageMetaResolver,
  PageRobotsMeta,
  PageRobotsResolver,
  PageTitleResolver,
  RoutingService,
  TranslationService,
} from '@spartacus/core';
import { Observable, of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BossPointOfService } from '../../../shared/models/store.models';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { BossStorefinderService } from '../../../features/storefinder/services/storefinder.service';

@Injectable({
  providedIn: 'root',
})
export class BossStorePageMetaResolver
  extends PageMetaResolver
  implements PageBreadcrumbResolver, PageTitleResolver, PageDescriptionResolver, PageRobotsResolver, OnDestroy
{
  storeFinderLabel = '';
  private bossSiteName = 'Möbel Boss';

  private subscription = new Subscription();

  constructor(
    private routingService: RoutingService,
    private translation: TranslationService,
    private router: Router,
    private bossStorefinderService: BossStorefinderService,
  ) {
    super();
    this.pageTemplate = 'StoreFinderDetailsPageTemplate';
    this.subscription.add(
      this.translation.translate('storeFinder.search').subscribe((label) => {
        this.storeFinderLabel = label;
      }),
    );
  }

  storeDetails$: Observable<BossPointOfService | HttpErrorResponse> = this.routingService.getParams().pipe(
    switchMap((params) => {
      const storeName = params.storeName ?? '';
      return this.bossStorefinderService.getStoreByName(storeName);
    }),
  );

  resolveTitle(): Observable<string> {
    return this.storeDetails$.pipe(
      map((store: BossPointOfService) => {
        return store.pageTitle + ' | ' + this.bossSiteName;
      }),
    );
  }

  resolveBreadcrumbs(): Observable<BreadcrumbMeta[]> {
    return this.storeDetails$.pipe(
      map((store: BossPointOfService) => {
        const breadcrumbs: BreadcrumbMeta[] = [];
        breadcrumbs.push({
          label: this.storeFinderLabel,
          link: 'storefinder',
        } as BreadcrumbMeta);
        breadcrumbs.push({
          label: store.displayName,
          link: 'STORE',
        } as BreadcrumbMeta);
        return breadcrumbs;
      }),
    );
  }

  resolveDescription(): Observable<string> {
    return this.storeDetails$.pipe(
      map((store: BossPointOfService) => {
        return store?.metaDescription ? store.metaDescription : '';
      }),
    );
  }

  resolveCanonicalUrl(): Observable<string> {
    return of('https://moebel-boss.de' + this.router.url);
  }

  resolveRobots(): Observable<PageRobotsMeta[]> {
    if (!environment.production) {
      return of([PageRobotsMeta.NOFOLLOW, PageRobotsMeta.NOINDEX]);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
