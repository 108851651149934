import { ChangeDetectionStrategy, Component } from '@angular/core';
import { bossIconConfig } from '../../shared/utils/boss-icon-config';
import { BossStorefinderService } from '../storefinder/services/storefinder.service';

@Component({
  selector: 'boss-my-market',
  templateUrl: './my-market.component.html',
  styleUrls: ['./my-market.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossMyMarketComponent {
  elementUrl: string = 'storefinder';
  routerLink: string = '/storefinder';
  bossIconConfig = bossIconConfig;

  store$ = this.myMarketService.getMyMarket();

  constructor(private myMarketService: BossStorefinderService) {}
}
