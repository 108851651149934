<div class="d-block">
  <h2 class="boss-h4 mb-4">{{ 'productDetails.zipCodeModal.title' | cxTranslate }}</h2>
  <p>{{ 'productDetails.zipCodeModal.subtitle' | cxTranslate }}</p>
</div>

<form (ngSubmit)="submitPostCode(zipCode.value)">
  <div class="boss-form-element">
    <input
      #zipCode
      [(ngModel)]="postcode"
      id="hybridDeliveryForm-zipCode"
      class="boss-form-control"
      placeholder="{{ 'generalForm.postalCode.labelMandatory' | cxTranslate }}"
      name="zipCode"
      type="text"
      pattern="[0-9]{5}"
      minlength="5"
      maxlength="5" />
  </div>

  <div class="boss-form-actions justify-content-center mt-4">
    <button [disabled]="!validPostCode || isLoading" class="boss-btn boss-btn-magenta" type="submit"
      >{{ 'common.take' | cxTranslate }}
    </button>
  </div>
</form>
