import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { BREAKPOINT, BreakpointService, CurrentProductService } from '@spartacus/storefront';
import { Subject } from 'rxjs';
import { ImageGroup, Product, WindowRef } from '@spartacus/core';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { SwiperOptions } from 'swiper';
import { bossIconConfig } from '../../../../shared/utils/boss-icon-config';
import { BossCarouselBaseComponent } from '../../../../shared/components/boss-carousel-base/boss-carousel-base.component';
import { BossWishlistService } from '../../../wishlist/wishlist.service';

@Component({
  selector: 'boss-product-images',
  templateUrl: './product-images.component.html',
  styleUrls: ['./product-images.component.scss'],
  host: { '[class.product-images]': 'true' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BossProductImagesComponent extends BossCarouselBaseComponent implements AfterViewInit, OnDestroy, OnInit {
  activeImage!: ImageGroup;

  thumbs: ImageGroup[] = [];

  activeIndex: number = 0;

  zoomActivated: boolean = false;

  zoomStyles: string = '';

  isMobile: boolean = false;

  product: Product;

  isOnWishlist: boolean = false;

  bossIconConfig = bossIconConfig;

  private onDestroy$ = new Subject<void>();

  readonly swiperConfigServer: SwiperOptions = {
    virtual: false,
    slidesPerView: 4,
    slidesPerGroup: 4,
    spaceBetween: 32,
  };

  readonly swiperConfigBrowser: SwiperOptions = {
    breakpoints: {
      200: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
      992: {
        slidesPerView: 4,
        slidesPerGroup: 4,
      },
    },
    navigation: {
      prevEl: '.boss-product-images-button-prev',
      nextEl: '.boss-product-images-button-next',
    },
    slidesPerGroup: 4,
    slidesPerView: 4,
    pagination: true,
    spaceBetween: 32,
    lazy: true,
    preloadImages: false,
    zoom: true,
    passiveListeners: false,
  };

  @ViewChild('mainImage') mainImage: ElementRef<HTMLDivElement>;

  constructor(
    private currentProductService: CurrentProductService,
    private wishlistService: BossWishlistService,
    private breakpointService: BreakpointService,
    private cdRef: ChangeDetectorRef,
    private windowRef: WindowRef,
  ) {
    super(windowRef);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.breakpointService
      .isDown(BREAKPOINT.md)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((isMobile: boolean) => {
        this.isMobile = isMobile;
        this.cdRef.detectChanges();
      });
  }

  ngAfterViewInit(): void {
    this.windowRef.resize$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.zoomActivated = false;
      this.cdRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  toggleZoom(ev: MouseEvent): void {
    ev.preventDefault();

    if (this.isMobile || !this.canBeZoomed) {
      return;
    }

    this.zoomActivated = !this.zoomActivated;

    this.cdRef.detectChanges();
  }

  mouseMoved(ev: MouseEvent): void {
    ev.preventDefault();

    if (this.isMobile || !this.zoomActivated || !this.canBeZoomed || !this.mainImage) {
      return;
    }

    this.moveImage(ev);
  }

  toggleFromWishList(product: Product, isOnWishlist: boolean): void {
    this.wishlistService.toggleFromWishlist(product.code, isOnWishlist);
  }

  get canBeZoomed(): boolean {
    if (!this.activeImage) {
      return false;
    }
    return !!this.activeImage.zoom;
  }

  setActiveByIndex(index: number): void {
    if (this.isMobile) {
      return;
    }

    this.activeIndex = index;
    this.activeImage = this.items[this.activeIndex];
    this.zoomActivated = false;

    this.cdRef.detectChanges();
  }

  protected loadItems(): void {
    this.currentProductService
      .getProduct()
      .pipe(
        tap((product: Product) => {
          if (product) {
            this.product = product;
            this.activeImage = (product.images?.PRIMARY || {}) as ImageGroup;
            this.items = [...this.getThumbs(product)];
          }
        }),
        switchMap((product: Product) => this.wishlistService.isProductOnWishlist(product)),
        takeUntil(this.onDestroy$),
      )
      .subscribe((isOnWishlist: boolean) => {
        this.isOnWishlist = isOnWishlist;

        this.cdRef.detectChanges();
      });
  }

  private getThumbs(product: Product): ImageGroup[] {
    return (product?.images?.GALLERY || []) as ImageGroup[];
  }

  private moveImage(ev: MouseEvent): void {
    const boundingClientRect = this.mainImage.nativeElement.getBoundingClientRect();
    const left = ev.clientX - boundingClientRect.left;
    const top = ev.clientY - boundingClientRect.top;

    this.zoomStyles = `left:-${left}px;top:-${top}px`;

    this.cdRef.detectChanges();
  }
}
