<div *ngIf="variantsSizes?.length">
  <boss-dropdown
    [label]="BossProductVariantLabel.Size"
    [labelClasses]="'mb-1 small-copy'"
    [list]="variantsSizes"
    [useRadio]="true"
    [listLabel]="'name'"
    [listValue]="'id'"
    [selectedValue]="selectedSize"
    (selected)="goToProduct($event)"></boss-dropdown>
</div>

<div *ngIf="variantsMaterials?.length">
  <boss-dropdown
    [label]="BossProductVariantLabel.Material"
    [labelClasses]="'mb-1 small-copy'"
    [list]="variantsMaterials"
    [useRadio]="true"
    [listLabel]="'name'"
    [listValue]="'id'"
    [selectedValue]="selectedMaterial"
    (selected)="goToProduct($event)"></boss-dropdown>
</div>

<div *ngIf="variantsEffectuations?.length">
  <boss-dropdown
    [label]="BossProductVariantLabel.Type"
    [labelClasses]="'mb-1 small-copy'"
    [list]="variantsEffectuations"
    [useRadio]="true"
    [listLabel]="'name'"
    [listValue]="'id'"
    [selectedValue]="selectedEffectuation"
    (selected)="goToProduct($event)"></boss-dropdown>
</div>
