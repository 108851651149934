import { Injectable } from '@angular/core';
import { defer, Observable, of, ReplaySubject } from 'rxjs';
import { BossStoreAvailability, BossStoreAvailabilityProduct } from './store-availability.model';
import { tap } from 'rxjs/operators';
import { BossPointOfService } from '../../../../shared/models/store.models';
import { BossStorefinderService } from '../../../storefinder/services/storefinder.service';

@Injectable({
  providedIn: 'root',
})
export class BossStoreAvailabilityService {
  getStoreAvailability$: Observable<BossStoreAvailability[]> = defer(() => this.storeAvailabilityState.asObservable());

  getProductDetails$: Observable<BossStoreAvailabilityProduct> = defer(() => this.productDetailsState.asObservable());

  private storeAvailabilityState = new ReplaySubject<BossStoreAvailability[]>(1);

  private productDetailsState = new ReplaySubject<BossStoreAvailabilityProduct>(1);

  private storeCollection = new Map<string, BossPointOfService[]>();

  constructor(private bossStorefinderService: BossStorefinderService) {}

  nextStoreAvailability(payload: BossStoreAvailability[]): void {
    this.storeAvailabilityState.next(payload);
  }

  nextProductDetails(code: string, quantity: number, price: number): void {
    this.productDetailsState.next({ code, quantity, price });
  }

  fetchStores(productCode: string, queryParam?: string): Observable<BossPointOfService[]> {
    if (this.storeCollection.has(productCode) && !queryParam) {
      return of(this.storeCollection.get(productCode));
    }

    if (this.storeCollection.has(queryParam)) {
      return of(this.storeCollection.get(queryParam));
    }

    return this.bossStorefinderService.getStoresByQuery(queryParam).pipe(
      tap((stores: BossPointOfService[]) => {
        if (queryParam) {
          this.storeCollection.set(queryParam, stores);
        } else {
          this.storeCollection.set(productCode, stores);
        }
      }),
    );
  }
}
