import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { BossNavigationService } from './boss-navigation.service';
import { filter, take, takeUntil, throttle } from 'rxjs/operators';
import { Subject, fromEvent, interval } from 'rxjs';
import { NavCategoryLink, NavigationSubLevel } from './boss-navigation.model';
import { RoutingService, WindowRef } from '@spartacus/core';
import { bossIconConfig } from '../../shared/utils/boss-icon-config';
import { BREAKPOINT, BreakpointService } from '@spartacus/storefront';

interface BossNavMobileItem extends NavigationSubLevel {
  hasArrow?: boolean;
}

@Component({
  selector: 'boss-navigation',
  templateUrl: './boss-navigation.component.html',
  styleUrls: ['./boss-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BossNavigationComponent implements OnInit, OnDestroy {
  @HostListener('document:click', ['$event'])
  clickOutside(event: MouseEvent): void {
    if (!this.el.nativeElement.contains(event.target) && this.anyItemVisible()) {
      this.resetItemsVisibility();
    }
  }

  bossIconConfig = bossIconConfig;

  nodes: NavigationSubLevel[] = [];

  linksPerColumn = 5;

  itemsVisibility: boolean[] = [];

  activeItem$: Subject<number> = new Subject();

  isMobile: boolean;

  mobileNodes: BossNavMobileItem[] = [];

  prevNodes: BossNavMobileItem[] = [];

  private nodeExceptions: string[] = [
    'moebel-C30',
    'kueche-C400',
    'haushalt-deko',
    'lampen',
    'storefinder',
    '40-Jahre-Jubilaeum',
    'angebote',
  ];

  private lastItem: number;

  private onDestroy$ = new Subject<void>();

  constructor(
    private bossNavigationService: BossNavigationService,
    private cdRef: ChangeDetectorRef,
    private el: ElementRef,
    private routingService: RoutingService,
    private winRef: WindowRef,
    private breakpointService: BreakpointService,
  ) {}

  ngOnInit(): void {
    this.breakpointService
      .isDown(BREAKPOINT.md)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((isMobile: boolean) => {
        this.isMobile = isMobile;

        this.cdRef.detectChanges();
      });

    this.prepareNodes();
    this.setDefaultPrevNodes();

    // Menu item change
    this.activeItem$.pipe(takeUntil(this.onDestroy$)).subscribe((i: number) => {
      this.resetItemsVisibility();
      this.itemsVisibility[i] = true;
      this.lastItem = i;

      this.cdRef.detectChanges();
    });

    this.routingService
      .isNavigating()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((isNavigating) => {
        if (isNavigating) {
          // Close menu on route change
          this.resetItemsVisibility();
          // Reset mobile nodes
          this.setDefaultMobileNodes();

          this.cdRef.detectChanges();
        }
      });

    // Close desktop menu on scroll
    if (this.winRef.isBrowser()) {
      fromEvent(window, 'scroll')
        .pipe(
          throttle(() => interval(50)),
          filter(() => this.anyItemVisible()),
        )
        .subscribe(() => {
          this.resetItemsVisibility();

          this.cdRef.detectChanges();
        });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  trackByFn(index: number, link: NavCategoryLink): string {
    return link.uid;
  }

  clickNode(i: number): void {
    if (this.anyItemVisible() && i === this.lastItem) {
      // Clicking again on the same menu item
      this.resetItemsVisibility();

      this.cdRef.detectChanges();
    } else {
      this.activeItem$.next(i);
    }
  }

  clickMobileNode(node: BossNavMobileItem): void {
    if (!node.children?.length) {
      // Clickable item without children
      this.routingService.go(node.url);

      this.setDefaultMobileNodes();
    } else {
      // Sub category with children
      this.setNewMobileNode(node);
      this.prevNodes.push(node);
    }
  }

  goBack(): void {
    this.prevNodes.pop();
    const prevNode = this.prevNodes[this.prevNodes.length - 2];

    if (prevNode) {
      this.setNewMobileNode(prevNode);
    } else {
      this.setDefaultMobileNodes();
    }
  }

  goToParent(url: string): void {
    this.routingService.go(url);
  }

  private setNewMobileNode(node: BossNavMobileItem): void {
    this.mobileNodes.push({
      title: node.title,
      url: node.url,
      hasArrow: false,
    });

    this.mobileNodes = node.children.map((node: NavigationSubLevel) => ({
      title: node.title,
      url: node.url,
      children: node.children,
      hasArrow: !!node.children?.length,
    }));
  }

  private prepareNodes(): void {
    this.bossNavigationService
      .getNavigationSubLevels()
      .pipe(take(1), takeUntil(this.onDestroy$))
      .subscribe((nodes: NavigationSubLevel[]) => {
        this.nodes = this.getMappedExceptions(nodes);

        this.setDefaultMobileNodes();

        this.resetItemsVisibility();

        this.cdRef.detectChanges();
      });
  }

  private setDefaultPrevNodes(): void {
    this.prevNodes = [
      {
        title: 'Hauptmenü',
      },
    ] as BossNavMobileItem[];
  }

  private setDefaultMobileNodes(): void {
    this.mobileNodes = this.nodes.map(
      (node): BossNavMobileItem => ({
        title: node.title,
        url: node.url,
        children: node.children,
        hasArrow: !!node.children?.length,
      }),
    );

    this.setDefaultPrevNodes();

    this.cdRef.detectChanges();
  }

  private getMappedExceptions(nodes: NavigationSubLevel[]): NavigationSubLevel[] {
    const exceptions = new Set(this.nodeExceptions);

    return nodes.map((node: NavigationSubLevel) => ({
      ...node,
      skipLinks: exceptions.has(node.url?.slice(1)),
    }));
  }

  private resetItemsVisibility(): void {
    this.itemsVisibility = new Array(this.nodes.length).fill(false);
  }

  private anyItemVisible(): boolean {
    return this.itemsVisibility.some((item: boolean) => !!item);
  }
}
