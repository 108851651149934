<ng-container *ngIf="product">
  <div class="boss-buy-box-name">
    <boss-product-status
      *ngIf="hasStatus"
      class="d-flex justify-content-end align-items-center"
      [product]="product"
      [max]="2"></boss-product-status>
    <h3 class="boss-h3 boss-product-name text-break mb-2 text-transform-none">{{ product.title }}</h3>
    <p class="boss-product-summary mb-2 small-copy" *ngIf="product.summary">{{ product.summary }}</p>
    <boss-star-rating
      *ngIf="product.averageRating"
      [rating]="product.averageRating"
      [count]="product.reviews?.length"
      [link]="context + '#product-reviews'"></boss-star-rating>
  </div>

  <div class="boss-buy-box-price">
    <boss-price [product]="product"></boss-price>
    <p class="small-copy mb-0">
      {{ 'productDetails.priceInfo.infoText' | cxTranslate }}
      {{ 'productDetails.priceInfo.delivery' | cxTranslate }}
      <boss-tooltip>
        <i bossIcon [type]="bossIconConfig.INFORMATION" aria-hidden="true" handle></i>
        <div>
          <span>{{ 'productDetails.priceInfo.toolTipHeader' | cxTranslate }}</span>
          <span>{{ product.shippingCosts }}€</span>
          <br />
          <span class="shipping-price-tooltip-body">{{ 'productDetails.priceInfo.toolTipBody' | cxTranslate }}</span>
        </div>
      </boss-tooltip>
      <span *ngIf="product.trackedPrice?.formattedValue && isPricedCrossed"> | </span>
      <boss-price-info [product]="product"></boss-price-info>
    </p>
    <button (click)="openFinanceCalculator()" class="boss-link" type="button">Finanzierungsrechner öffnen</button>
  </div>

  <boss-variant-selector *ngIf="hasOtherVariants" [product]="product"></boss-variant-selector>

  <boss-swatches
    *ngIf="colorVariants.length"
    [product]="product"
    [anchor]="true"
    [showSwatchLabel]="true"></boss-swatches>

  <div class="item-counter-wrapper">
    <p class="mb-1 small-copy item-counter-label">Menge</p>
    <boss-item-counter [control]="addToCartForm.get('quantity')" [max]="productMax"></boss-item-counter>
  </div>

  <div class="collapse-container" *ngIf="product.maxReturnAmount">
    <div
      class="handle d-flex justify-content-between align-items-center"
      (click)="maxReturnAmountCollapsed = !maxReturnAmountCollapsed"
      (keydown.enter)="maxReturnAmountCollapsed = !maxReturnAmountCollapsed">
      <span class="form-check-label">{{ 'productDetails.returnDevices.label' | cxTranslate }}</span>

      <i
        bossIcon
        [type]="maxReturnAmountCollapsed ? bossIconConfig.CARET_DOWN : bossIconConfig.CARET_UP"
        aria-hidden="true"></i>
    </div>
    <div class="collapse-content mt-2" *ngIf="!maxReturnAmountCollapsed">
      Bei diesem Produkt nehmen wir Ihr Elektro-Altgerät kostenlos gemäß Elektro- und Elektronikgerätegesetz zurück und
      entsorgen es fachgerecht für Sie.
      <strong>Weitere Informationen finden Sie im weiteren Kaufprozess.</strong> Allgemeine Informationen zur
      Altegeräteentsorgung <a target="_blank" routerLink="/service/altgeraeterueckgabe">finden Sie hier</a>.
    </div>
  </div>

  <hr class="boss-horizontal-line" />

  <div class="buy-box-actions">
    <div class="buy-box-row">
      <div class="buy-box-row-element first">
        <boss-status-label [status]="stockColor">
          <span>{{ stockLabel }} {{ deliveryTimeString }}</span>
          <span *ngIf="currentZip && product.hybridDeliverySupported">
            nach {{ currentZip }}.
            <button (click)="openPostCodeModal()" class="boss-btn-as-link buy-box-change-zip"
              >Postleitzahl ändern</button
            ></span
          >
        </boss-status-label>
      </div>
      <div class="buy-box-row-element second">
        <ng-container *ngIf="product.hybridDeliverySupported && !currentZip; else addToCart">
          <button type="button" class="boss-btn boss-btn-primary boss-btn-full" (click)="openPostCodeModal()">
            {{ 'productDetails.checkDeliveryTime' | cxTranslate }}
          </button>
        </ng-container>

        <ng-template #addToCart>
          <boss-add-to-cart
            [product]="product"
            [productCode]="product.code"
            [showQuantity]="false"
            [customQuantity]="addToCartForm.get('quantity').value"></boss-add-to-cart>
        </ng-template>

        <button
          *ngIf="product.purchasable && !product.onlyOnlineProduct && product.clickAndCollect && isStoreAvailable"
          type="button"
          class="boss-btn boss-btn-secondary boss-btn-full mt-3"
          (click)="openMarketAvailabilityModal()"
          [attr.disabled]="!isStoreAvailable ? true : null">
          {{ 'productDetails.pickUpInStore' | cxTranslate }}
        </button>
      </div>
    </div>

    <ng-container *ngIf="product.energyEfficiencies && product.energyEfficiencies.length">
      <hr class="boss-horizontal-line" />

      <span class="m-0 boss-h6">Energieeffizienz</span>

      <boss-energy-labels [product]="product" [expandedDesign]="true"></boss-energy-labels>
    </ng-container>
  </div>
  <boss-dialog-wrapper></boss-dialog-wrapper>
</ng-container>
