<button type="button" (click)="onDecrement()" [disabled]="control?.value <= min" tabindex="-1">
  <cx-icon type="SUBTRACT"></cx-icon>
</button>

<input
  type="number"
  [min]="min"
  [max]="max"
  [step]="step"
  [readonly]="readonly"
  [tabindex]="readonly ? -1 : 0"
  [formControl]="control" />

<button type="button" (click)="onIncrement()" [disabled]="control?.value >= max" tabindex="-1">
  <cx-icon type="ADD"></cx-icon>
</button>
