<ng-container *ngIf="pages.length">
  <div class="boss-pagination-pages">
    <div class="boss-pagination-pages-wrapper">
      <a
        *ngFor="let page of pages"
        [class]="page.type"
        [class.disabled]="isDisabled(page)"
        [class.current]="isCurrent(page)"
        [routerLink]="pageRoute"
        [queryParams]="getQueryParams(page)"
        [tabIndex]="isDisabled(page) ? -1 : 0"
        (click)="pageChange()">
        {{ page.label }}
      </a>
    </div>
  </div>

  <div class="boss-pagination-buttons">
    <a
      class="boss-btn boss-btn-primary"
      [routerLink]="pageRoute"
      [queryParams]="getQueryParams(prevPage)"
      [class.disabled]="isDisabled(prevPage)"
      (click)="pageChange()">
      <i bossIcon [type]="bossIconConfig.ARROW_LEFT" [iconSize]="16" aria-hidden="true"></i>
    </a>
    <a
      class="boss-btn boss-btn-primary"
      [routerLink]="pageRoute"
      [queryParams]="getQueryParams(nextPage)"
      [class.disabled]="isDisabled(nextPage)"
      (click)="pageChange()">
      <i bossIcon [type]="bossIconConfig.ARROW_RIGHT" [iconSize]="16" aria-hidden="true"></i>
    </a>
  </div>
</ng-container>
