<div
  *ngIf="product"
  [ngSwitch]="priceType"
  [ngClass]="priceType | bossPriceTypeClass: componentType"
  class="boss-custom-price">
  <ng-container *ngSwitchCase="BossPriceType.REGULAR">
    <span class="boss-custom-price-regular">{{ product.price?.formattedValue }}</span>
  </ng-container>

  <ng-container *ngSwitchCase="BossPriceType.CROSSED">
    <span class="boss-custom-price-old">{{ product.crossedPrice?.formattedValue }}</span>
    <span class="boss-custom-price-new">{{ product.price?.formattedValue }}</span>
  </ng-container>

  <ng-container *ngSwitchCase="BossPriceType.UVP">
    <span class="boss-custom-price-old-msrp">
      <strong class="boss-custom-price-old-msrp-label">{{ 'bossProduct.price.msrp' | cxTranslate }}: </strong>
      <span class="boss-custom-price-old-msrp-value">{{ product.crossedPrice?.formattedValue }}</span>
    </span>
    <span class="boss-custom-price-new">{{ product.price?.formattedValue }}</span>
  </ng-container>

  <ng-container *ngSwitchCase="BossPriceType.BOSS">
    <span
      [innerHTML]="'price.boss' | cxTranslate | bossSafe"
      class="boss-custom-price-boss boss-custom-price-top small-copy"></span>
    <span class="boss-custom-price-new">{{ product.price?.formattedValue }}</span>
  </ng-container>

  <ng-container *ngSwitchCase="BossPriceType.STABLE_LOW">
    <span
      [innerHTML]="'price.stableLow' | cxTranslate | bossSafe"
      class="boss-custom-price-low boss-custom-price-top small-copy"></span>
    <span class="boss-custom-price-new">{{ product.price?.formattedValue }}</span>
  </ng-container>

  <ng-container *ngSwitchCase="BossPriceType.SHRUNK">
    <span
      [innerHTML]="'price.shrunk' | cxTranslate | bossSafe"
      class="boss-custom-price-shrunk boss-custom-price-top small-copy"></span>
    <span class="boss-custom-price-new">{{ product.price?.formattedValue }}</span>
  </ng-container>
</div>
