<a
  *ngIf="store$ | async as store"
  class="marked"
  bossSetActiveState
  [elementUrl]="store.url ? store.url : elementUrl"
  [routerLink]="store.url ? '/' + store.url : routerLink">
  <i bossIcon class="market-icon" [type]="bossIconConfig.MAP_PIN" aria-hidden="true"></i>
  <span class="title">{{
    store.displayName?.trim() === 'Online Shop' ? ('bossMyMarket.title' | cxTranslate) : store.displayName
  }}</span>
</a>
