import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BossNavigationComponent } from './boss-navigation.component';
import { GenericLinkModule, IconModule, NavigationModule } from '@spartacus/storefront';
import { I18nModule, provideConfig } from '@spartacus/core';
import { BossIconDirectiveModule } from '../../shared/directives/icon/boss-icon.directive.module';
import { RouterModule } from '@angular/router';
import { BossMediaDirectiveModule } from '../../shared/directives/media/boss-media.directive.module';

@NgModule({
  declarations: [BossNavigationComponent],
  imports: [
    CommonModule,
    NavigationModule,
    IconModule,
    GenericLinkModule,
    I18nModule,
    BossIconDirectiveModule,
    RouterModule,
    BossMediaDirectiveModule,
  ],
  exports: [BossNavigationComponent],
  providers: [
    provideConfig({
      cmsComponents: {
        NavigationBarCollectionComponent: {
          component: BossNavigationComponent,
        },
      },
    }),
  ],
})
export class BossNavigationComponentModule {}
