<div
  #bossDialog
  [ngClass]="['boss-dialog-' + dialogType, 'boss-dialog']"
  [style.max-width]="dialogType === bossDialogType.MODAL ? modalWidth : null">
  <header *ngIf="isCloseButtonVisible" class="boss-dialog-header">
    <boss-modal-close-button (click)="close()" (keydown.enter)="close()"></boss-modal-close-button>
  </header>

  <main class="boss-dialog-body">
    <ng-template bossDialogHost></ng-template>
  </main>
</div>
<div class="boss-dialog-backdrop"></div>
